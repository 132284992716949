@charset 'UTF-8';

@import 'settings-local';
@import 'import-core-main';

@import './shared/elements/elements';
@import './shared/elements/icon';
@import './shared/elements/modal.scss';

body {
   &.has-menu-active {
    overflow-y: hidden;

    > app-root {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}

strong {
  font-weight: font-weight(bold);
}

a {
  color: color(brand-primary);

  &:hover {
    text-decoration: underline;
  }
}
