@import 'import-tools-settings';
@import 'settings-local';

html {
  scroll-behavior: smooth;
}

body {
  color: $color-neutral-low-light;
  background-color: color(ui-background-secondary);
}

html,
body,
p,
li {
  line-height: 1.5;
}
