// FIXME: variáveis temporárias cujo valores exatos não existem no figital, revisar componentes onde estão sendo utilizadas e trocar por uma equivalente

$color-dark-darkest: #000000;
$color-light-dark: #bbbbbb;
$color-light-ligth: #dedede;
$color-warning: #ff6633;
$color-cadet: #5a676f;
$color-granite-gray: #666666;
$color-cultured: #f7f7f7;
$color-gray-medium: #797979;
$color-neutral-low-light: #595959;
$color-anti-flash-white: #eff6f8;
$color-neutral-low-principal: #050505;
$color-neutral-high-light: #E6E6E6;

