.icon {
  &.to-right {
    transform: rotate(90deg);
  }

  &.to-bottom {
    transform: rotate(180deg);
  }

  &.to-left {
    transform: rotate(270deg);
  }
}
